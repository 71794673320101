import { useContext, useEffect } from "react";
import { LanguageContext } from "../../context/LanguageContext";

const AboutTwo2 = () => {
  const { lang } = useContext(LanguageContext);

  const aboutTow2List = Array.isArray(lang("about_tow2_list"))
    ? lang("about_tow2_list")
    : [];

  return (
    <div id="about" className="about-section">
      <div className="container">
        <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-center">
          <div className="col">
            <div className="about-left">
              <div className="about-thumb">
                <img src="assets/images/about/3.webp" alt="about" />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="about-right padding-tb">
              <div className="section-header">
                <span className="subtitle">{lang("about_tow2_subTitle")}</span>
                {/* <h2 className="title"></h2> */}
                <p>{lang("about_tow2_desc")}</p>
              </div>
              <div className="section-wrapper">
                <ul className="lab-ul mx-5">
                  {aboutTow2List.map((val, i) => (
                    <li className="py-1" key={i}>
                      <h5>
                        <i className="icofont-check-circled text-success"></i>
                        {val.title}
                      </h5>
                      <p>{val.desc}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo2;
