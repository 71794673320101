import React, { useContext, useEffect, useState, useCallback } from "react";
import axios from "axios";
import { LanguageContext } from "../../context/LanguageContext";
import Spinner from "./Spinner";
import { Tooltip } from "react-tooltip";

/* ----------------------------------
   FONCTIONS UTILITAIRES (DateUtils)
---------------------------------- */

// Récupère le lundi de la semaine
const getStartOfWeek = (date) => {
  const day = date.getDay(); // 0 (dimanche) -> 6 (samedi)
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};

// Ajoute ou soustrait des semaines
const addWeeks = (date, weeks) => {
  const result = new Date(date);
  result.setDate(result.getDate() + weeks * 7);
  return result;
};

// Retourne un tableau de 7 jours à partir d'un lundi
const getWeekDays = (startOfWeek) =>
  Array.from({ length: 7 }, (_, i) => {
    const day = new Date(startOfWeek);
    day.setDate(startOfWeek.getDate() + i);
    return day;
  });

// Formate une date
const formatDate = (date, options) =>
  new Intl.DateTimeFormat("en-US", options).format(date);

// Détermine si un jour est déjà passé
const isPastDay = (date) => {
  const today = new Date();
  const currentDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  return date < currentDay;
};

// Vérifie si c'est dans les 24 heures à venir
const isWithin24Hours = (day, timeStart) => {
  // timeStart est censé être une chaîne du style "09:00" ou "09:30"
  const [hours, minutes = "0"] = timeStart.split(":");
  const selectedDate = new Date(day);
  selectedDate.setHours(+hours);
  selectedDate.setMinutes(+minutes);

  // Compare au moment actuel + 24h
  const limit = new Date(Date.now() + 24 * 60 * 60 * 1000);
  return selectedDate <= limit;
};

/* -----------------------------------
   SOUS-COMPONENT: WeekNavigation
----------------------------------- */

const WeekNavigation = ({ onPrevWeek, onNextWeek, lang, onThisWeek }) => (
  <div className="d-flex flex-column flex-md-row gap-2 gap-md-3 mb-4">
    <button
      className="btn btn-outline-primary flex-grow-1 d-flex align-items-center justify-content-center py-2"
      onClick={onPrevWeek}
    >
      <i className="icofont-arrow-left me-2"></i>
      <span>{lang("semainePrecedente")}</span>
    </button>

    <button
      className="btn btn-primary flex-grow-1 d-flex align-items-center justify-content-center py-2"
      onClick={onThisWeek}
    >
      <i className="icofont-ui-calendar me-2"></i>
      <span>{lang("cetteSemaine")}</span>
    </button>

    <button
      className="btn btn-outline-primary flex-grow-1 d-flex align-items-center justify-content-center py-2"
      onClick={onNextWeek}
    >
      <span>{lang("semaineProchaine")}</span>
      <i className="icofont-arrow-right ms-2"></i>
    </button>
  </div>
);

/* --------------------------------
   SOUS-COMPONENT: DayItem
-------------------------------- */

const DayItem = ({
  day,
  dayDispos,
  timeSelect,
  bookedTime,
  selectTime,
  lang,
}) => {
  const weekdayName = formatDate(day, { weekday: "long" });
  const dayMonth = formatDate(day, { day: "2-digit", month: "2-digit" });

  const isBookedSlot = (currentDay, startTime) => {
    const formattedDay = formatDate(currentDay, {
      day: "2-digit",
      month: "2-digit",
    });
    return bookedTime.some(
      (booking) =>
        booking.day === formattedDay && booking.timeStart === startTime
    );
  };
  return (
    <div className="card mb-2">
      <div className="card-body p-3">
        <div className="row g-2 align-items-center">
          <div className="col-3">
            <div className="text-center">
              <div className="fw-bold">{weekdayName}</div>
              <div className="text-muted small">{dayMonth}</div>
            </div>
          </div>
          <div className="col-9">
            <div className="d-flex flex-wrap gap-1">
              {dayDispos ? (
                dayDispos.time.map((timeSlot, index) => {
                  const [startTime] = timeSlot;
                  const isSelected =
                    timeSelect.day === dayMonth &&
                    timeSelect.timeStart === startTime;
                  const isPast = isPastDay(day);
                  const isWithin24h = isWithin24Hours(day, startTime);
                  const isBooked = isBookedSlot(day, startTime);

                  let buttonClass = "btn btn-sm ";
                  if (isSelected) buttonClass += "btn-success";
                  else if (isPast) buttonClass += "btn-secondary opacity-50";
                  else if (isBooked) buttonClass += "btn-danger opacity-75";
                  else if (isWithin24h) buttonClass += "btn-outline-secondary";
                  else buttonClass += "btn-outline-success";

                  return (
                    <>
                      <button
                        key={index}
                        className={buttonClass}
                        onClick={() => {
                          if (!isPast && !isBooked && !isWithin24h) {
                            selectTime(day, timeSlot);
                          }
                        }}
                        data-tooltip-id={`tooltip-${index}-${dayMonth}`}
                        data-tooltip-content={
                          isPast
                            ? "Ce créneau est déjà passé et n'est plus disponible"
                            : isBooked
                            ? "Ce créneau a déjà été réservé par un autre utilisateur"
                            : isWithin24h
                            ? "Impossible de réserver moins de 24h à l'avance"
                            : "Ce créneau est disponible pour réservation"
                        }
                      >
                        {startTime}
                      </button>
                      <Tooltip
                        id={`tooltip-${index}-${dayMonth}`}
                        place="top"
                      />
                    </>
                  );
                })
              ) : (
                <span className="text-muted small">
                  {lang("noAvailableTimes")}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/* --------------------------------
     COMPOSANT PRINCIPAL: Agenda
-------------------------------- */

const Agenda = ({ dispo, onclickTime }) => {
  const [currentWeek, setCurrentWeek] = useState(getStartOfWeek(new Date()));
  const [timeSelect, setTimeSelect] = useState({});
  const [bookedTime, setBookedTime] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const { lang } = useContext(LanguageContext);

  const fetchBookings = useCallback(async (weekStart) => {
    try {
      setSpinner(true);
      const { data } = await axios.get("/api/getBookings", {
        params: { weekStart: weekStart.getTime() },
      });
      setBookedTime(data.bookings || []);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    } finally {
      setSpinner(false);
    }
  }, []);

  const changeWeek = (weeks) => {
    const newWeek = addWeeks(currentWeek, weeks);
    const todayStartOfWeek = getStartOfWeek(new Date());
    const updatedWeek = newWeek < todayStartOfWeek ? todayStartOfWeek : newWeek;
    setCurrentWeek(updatedWeek);
  };

  const selectTime = (day, [startTime, endTime]) => {
    const selectedTime = {
      fullDay: formatDate(day, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      day: formatDate(day, { day: "2-digit", month: "2-digit" }),
      timeStart: startTime,
      timeEnd: endTime,
    };
    setTimeSelect(selectedTime);
    onclickTime(selectedTime);
  };

  useEffect(() => {
    fetchBookings(currentWeek);
  }, [currentWeek, fetchBookings]);

  return (
    <div className="container py-4">
      <div className="card border-0 shadow-lg">
        <div className="card-body">
          <div className="text-center mb-4">
            <h3 className="card-title text-primary d-flex align-items-center justify-content-center gap-2">
              {lang("calendrier")}
              <small className="text-muted fs-6 fw-normal">
                <i className="icofont-clock-time me-1"></i>
                Heure de Casablanca
              </small>
            </h3>
          </div>

          <WeekNavigation
            onPrevWeek={() => changeWeek(-1)}
            onNextWeek={() => changeWeek(1)}
            onThisWeek={() => setCurrentWeek(getStartOfWeek(new Date()))}
            lang={lang}
          />

          {spinner ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <div className="day-list">
              {getWeekDays(currentWeek).map((day, index) => {
                const weekdayName = formatDate(day, { weekday: "long" });
                const dayDispos = dispo.find((d) => d.jours === weekdayName);

                return (
                  <DayItem
                    key={index}
                    day={day}
                    dayDispos={dayDispos}
                    timeSelect={timeSelect}
                    bookedTime={bookedTime}
                    selectTime={selectTime}
                    lang={lang}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Agenda;
