import React from "react";

const Spinner = ({ style }) => {
  const styleDefault = style ?? {
    width: "200px",
    marginBottom: "20px",
    animation: "fadeIn 0.5s ease-in-out",
  };
  return (
    <img src="/assets/images/logo/loading.gif" alt="Loading..." style={styleDefault} />
  );
};

export default Spinner;
