import axios from "axios";
import { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { LanguageContext } from "../../context/LanguageContext";

const FilterSecondary = (props) => {
  const { lang } = useContext(LanguageContext);

  // État des filtres sélectionnés
  const [selectedFilters, setSelectedFilters] = useState({
    genre: props.genre ?? null,
    price: props.price ?? 0,
    langueEn: props.langueEn ?? null,
    typeRencontre: props.typeRencontre ?? null,
  });

  // Données de référence (langue, genre, type de rencontre)
  const [data, setData] = useState({
    langue: [],
    genre: [],
    typeRencontre: [],
  });

  /**
   * Récupère la liste des langues d’enseignement.
   */
  const fetchLangueEnseignement = async () => {
    try {
      const response = await axios.get("/api/langueEnseignement");
      const langueData = response.data;
      if (langueData?.status === true) {
        const languesArray = langueData.data.map(({ id, nom }) => ({
          value: id,
          label: nom,
        }));
        setData((prev) => ({ ...prev, langue: languesArray }));
      }
    } catch (error) {
      console.error("Error fetching langueEnseignement:", error);
    }
  };

  /**
   * Récupère la liste des types de rencontre.
   */
  const fetchTypeRencontre = async () => {
    try {
      const response = await axios.get("/api/rencontre");
      const rencontreData = response.data.data.map((item) => ({
        value: item.id,
        label: item.nom,
      }));
      setData((prev) => ({ ...prev, typeRencontre: rencontreData }));
    } catch (error) {
      console.error("Error fetching typeRencontre:", error);
    }
  };

  /**
   * Chargement initial des données : langues, types de rencontre et genre.
   */
  useEffect(() => {
    fetchLangueEnseignement();
    fetchTypeRencontre();

    // Ajout des options pour le genre
    setData((prev) => ({
      ...prev,
      genre: [
        { value: 0, label: lang("all") },
        { value: "M", label: "M" },
        { value: "F", label: "F" },
      ],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Met à jour l'état des filtres sélectionnés.
   */
  const handleOtherChange = (field) => (value) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  /**
   * Lors de la soumission, on remonte les filtres sélectionnés au parent.
   */
  const handleSubmit = () => {
    props.onClick(selectedFilters);
  };

  return (
    <div className="row align-items-end justify-content-center">
      <div className="col-12" />

      <div className="row-cols-1 align-items-center justify-content-center">
        {/* Langue d’enseignement */}
        <div className="col p-1">
          <label htmlFor="LangueEnseignement">
            {lang("langue_enseignement")}
          </label>
          <Select
            onChange={handleOtherChange("langueEn")}
            placeholder={lang("all")}
            isMulti
            inputId="LangueEnseignement"
            isLoading={data.langue.length === 0}
            value={selectedFilters.langueEn}
            options={data.langue}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        </div>

        {/* Genre */}
        <div className="col p-1">
          <label htmlFor="genre">{lang("genre")}</label>
          <Select
            onChange={handleOtherChange("genre")}
            placeholder=""
            inputId="genre"
            isLoading={data.genre.length === 0}
            value={selectedFilters.genre}
            options={data.genre}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        </div>

        {/* Type de rencontre */}
        <div className="col p-1">
          <label htmlFor="TypeRencontre">{lang("typeRencontre")}</label>
          <Select
            onChange={handleOtherChange("typeRencontre")}
            placeholder={lang("all")}
            inputId="TypeRencontre"
            isLoading={!data.typeRencontre.length}
            value={selectedFilters.typeRencontre}
            options={data.typeRencontre}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            isMulti
          />
        </div>

        {/* Prix max */}
        <div className="col p-1">
          <label htmlFor="price">{lang("prixMax")}</label>
          <input
            type="number"
            value={selectedFilters.price}
            onChange={(e) =>
              handleOtherChange("price")(parseFloat(e.target.value) || 0)
            }
            min="0"
            max="1000"
            className="form-control"
            aria-describedby="priceMax"
          />
          <input
            type="range"
            value={selectedFilters.price}
            onChange={(e) =>
              handleOtherChange("price")(parseFloat(e.target.value) || 0)
            }
            min="0"
            max="1000"
            className="form-range"
            id="priceMaxRange"
          />
        </div>
      </div>

      {/* Bouton de soumission */}
      <div className="col-12">
        <button
          type="button"
          onClick={handleSubmit}
          className="border border-primary border-2 px-4 py-1 bg-primary rounded"
        >
          <i className="icofont-search fw-bolder text-white fs-5" />
        </button>
      </div>
    </div>
  );
};

export default FilterSecondary;
