import axios from "axios";
import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { AuthContext } from "../../context/AuthContext";
import { LanguageContext } from "../../context/LanguageContext";

const socialList = [
  {
    iconName: "icofont-facebook",
    siteLink: document
      .querySelector("meta[name='url-facebook']")
      .getAttribute("content"),
    className: "facebook",
  },
  {
    iconName: "icofont-linkedin",
    siteLink: document
      .querySelector("meta[name='url-linkedin']")
      .getAttribute("content"),
    className: "linkedin",
  },
  {
    iconName: "icofont-instagram",
    siteLink: document
      .querySelector("meta[name='url-instagram']")
      .getAttribute("content"),
    className: "instagram",
  },
];

const languages = [
  { code: "ar", label: "Ar" },
  { code: "en", label: "En" },
  { code: "es", label: "Es" },
  { code: "fr", label: "Fr" },
  { code: "ge", label: "Ge" },
  { code: "it", label: "It" },
];

const urlProvider = document
  .querySelector("meta[name='url-provider']")
  .getAttribute("content");

const Header = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { language, lang, changeLanguage } = useContext(LanguageContext);

  const navigate = useNavigate();
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  const logoutSubmit = (e) => {
    e.preventDefault();
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/logout").then((res) => {
        localStorage.removeItem("auth_token");
        swal("Success", res.data.message, "success");
        window.location.reload();
      });
    });
  };

  return (
    <header
      className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}
    >
      <div className={`header-top ${socialToggle ? "open" : ""}`}>
        <div className="container">
          <div className="header-top-area">
            <ul className="lab-ul left">
              <li>
                <i className="icofont-ui-call"></i> <span>+212 659694741</span>
              </li>
            </ul>
            <ul className="lab-ul social-icons d-flex align-items-center">
              <li>
                <p>{lang("find us on")} : </p>
              </li>
              {socialList.map((val, i) => (
                <li key={i}>
                  <a href={val.siteLink} target="_blank" rel="noreferrer">
                    <i className={val.iconName}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div
        className="z-3 header-bottom"
        style={{ boxShadow: "0px 19px 39px -24px #0000003b" }}
      >
        <div className="container-xxl">
          <div className="header-wrapper">
            <div className="logo">
              <Link to="/">
                <img
                  src="/assets/images/logo/01.png"
                  alt="logo"
                />
              </Link>
            </div>
            <div className="menu-area">
              <div className="menu">
                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                  <li>
                    <Link to="/?scroll=about">{lang("about")}</Link>
                  </li>
                  <li>
                    <Link to="/?scroll=services">{lang("our services")}</Link>
                  </li>
                  <li>
                    <Link to="/?scroll=features">{lang("our strengths")}</Link>
                  </li>
                  <li>
                    <a href={urlProvider} target="_blank" rel="noreferrer">
                      {lang("become an expert")}
                    </a>
                  </li>
                  <li className="menu-item-has-children" dir="ltr">
                    <a
                      href="#lang"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      {language}
                    </a>
                    <ul className="lab-ul dropdown-menu w-100">
                      {languages.map((lang) => (
                        <li key={lang.code}>
                          <a
                            href={`#${lang.label}`}
                            onClick={() => changeLanguage(lang.code)}
                          >
                            <span>
                              <i className="icofont-history pe-2"></i>
                              {lang.label}
                            </span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                  {isAuthenticated ? (
                    <li className="menu-item-has-children">
                      <a
                        href="#username"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-bs-offset="0,0"
                        style={{
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                      >
                        <img
                          src={localStorage.getItem("auth_photos")}
                          alt="photos profile"
                          className="me-1"
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                            backgroundColor: "#f16126",
                            padding: "3px",
                          }}
                        />
                        {localStorage.getItem("auth_username").split(" ")[0]}
                      </a>
                      <ul className="lab-ul dropdown-menu w-100">
                        <li>
                          <a onClick={() => navigate("/history")} href="#home">
                            <span>
                              <i className="icofont-history pe-2"></i>
                              {lang("history")}
                            </span>
                          </a>
                        </li>
                        <li>
                          <a onClick={() => navigate("/settings")} href="#home">
                            <span>
                              <i className="icofont-settings-alt pe-2"></i>
                              {lang("settings")}
                            </span>
                          </a>
                        </li>
                        <li>
                          <a onClick={logoutSubmit} href="#logout">
                            <span>
                              <i className="icofont-logout pe-2"></i>
                              {lang("logout")}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <>
                      <li>
                        <Link to="/login" className="login">
                          <span>
                            <i className="icofont-user"></i>
                            {lang("connexion")}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/signup" className="signup">
                          <span>
                            <i className="icofont-users"></i>
                            {lang("inscription")}
                          </span>
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>

              <div
                className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                onClick={() => setMenuToggle(!menuToggle)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="ellepsis-bar d-lg-none"
                onClick={() => setSocialToggle(!socialToggle)}
              >
                <i className="icofont-info-square"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
