import { Fragment, useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import PageHeader from "../component/layout/pageheader";
import Agenda from "../component/element/agenda";
import LoginSignup from "../component/element/loginSignup";
import { AuthContext } from "../context/AuthContext";
import StripeRedirect from "../component/element/stripeRedirect";
import { LanguageContext } from "../context/LanguageContext";
import Spinner from "../component/element/Spinner";

const CartPage = () => {
  const { id: expertId } = useParams();
  const { isAuthenticated, authUserEmail, authUserToken } =
    useContext(AuthContext);
  const { lang, dir, language } = useContext(LanguageContext);

  // States for booking data
  const [bookingData, setBookingData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  // Other state management
  const [programme, setProgramme] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [error, setError] = useState(null);
  const [timeSelect, setTimeSelect] = useState({
    fullDay: null,
    day: null,
    timeStart: null,
    timeEnd: null,
  });
  const [selected, setSelected] = useState({
    cours: "0",
    rencontre: null,
  });
  const [paymentStatus, setPaymentStatus] = useState({
    inProgress: false,
    publishableKey: "",
    sessionId: "",
  });
  const [price, setPrice] = useState({
    price: 0,
    PriceChargePer: 0,
    total: 0,
  });

  // Fetch booking data
  useEffect(() => {
    const fetchBookingData = async () => {
      setIsLoading(true);
      setFetchError(null);

      try {
        const response = await axios.get(
          `api/user/booking-data/${expertId}/${language}`
        );
        const formattedData = {
          cours: response.data.cours,
          rencontre: response.data.rencontre.map((val) => ({
            value: val.id,
            label: `${val.rencontre} - ${val.price} ${response.data.currency}/30min`,
            price: val.price,
          })),
          dispo: response.data.dispo,
          chargeFix: response.data.chargeFix,
          chargePer: response.data.chargePer,
          currency: response.data.currency,
        };
        setBookingData(formattedData);
      } catch (err) {
        setFetchError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBookingData();
  }, [expertId]); // Only include `expertId`

  // Event handlers
  const handleCoursChange = (courseIndex) => {
    const selectedCours = bookingData?.cours[courseIndex];
    if (selectedCours?.programme) {
      setProgramme(
        selectedCours.programme.map((item) => ({
          id: item.id,
          nom: item.nom,
          relationId: item.relationId,
          checked: true,
        }))
      );
    }
    setSelected((prev) => ({ ...prev, cours: courseIndex }));
  };

  const handleRencontreChange = (value) => {
    setSelected((prev) => ({ ...prev, rencontre: value }));

    const selectedRencontre = bookingData?.rencontre?.find(
      (ren) => ren.value.toString() === value.toString()
    );

    if (selectedRencontre?.price !== undefined) {
      const amount = selectedRencontre.price;
      const chargePer = (
        amount *
        (parseFloat(bookingData.chargePer) / 100)
      ).toFixed(2);
      setPrice({
        price: amount,
        PriceChargePer: chargePer,
        total:
          parseFloat(amount) +
          parseFloat(chargePer) +
          parseFloat(bookingData.chargeFix),
      });
    } else {
      setPrice({
        price: 0,
        PriceChargePer: 0,
        total: 0,
      });
    }
  };

  const selectAll = (e) => {
    const newCheckedState = e.target.checked;
    setIsChecked(newCheckedState);
    setProgramme((prev) =>
      prev.map((item) => ({ ...item, checked: newCheckedState }))
    );
  };

  const handleCheckboxChange = (index) => {
    setProgramme((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const handlePayment = async () => {
    setPaymentStatus((prev) => ({ ...prev, inProgress: true }));
    setError(null);

    // Validation
    const isProgrammeChecked = programme.some((prog) => prog.checked);
    if (!isAuthenticated) {
      setError([lang("messageErreur2_cartPage")]);
      setPaymentStatus((prev) => ({ ...prev, inProgress: false }));
      return;
    }

    if (!timeSelect.day || !isProgrammeChecked || !selected.rencontre) {
      setError([lang("messageErreur1_cartPage")]);
      setPaymentStatus((prev) => ({ ...prev, inProgress: false }));
      return;
    }

    try {
      const selectedCourse = bookingData.cours[selected.cours];
      const paymentData = {
        expertId: parseInt(expertId),
        auth_token: authUserToken,
        userEmail: authUserEmail,
        paiementMethode: "stripe",
        timeSelect,
        rencontre: parseInt(selected.rencontre),
        domaine: selectedCourse.domaine,
        matier_id: parseInt(selectedCourse.matier_id),
        niveau_id: parseInt(selectedCourse.niveau_id),
        option_id: parseInt(selectedCourse.option_id),
        programme: programme
          .filter((prog) => prog.checked)
          .map((prog) => ({
            id: prog.id,
            relationId: prog.relationId,
          })),
        message: document.getElementById("message")?.value || "",
      };

      const response = await axios.post(`/api/depositInsert`, paymentData);

      if (response.data.success) {
        setPaymentStatus({
          inProgress: true,
          publishableKey: response.data.data.publishable_key,
          sessionId: response.data.data.session_id,
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (err) {
      setError([err.message]);
      setPaymentStatus((prev) => ({ ...prev, inProgress: false }));
    }
  };

  // Loading state
  if (isLoading) {
    return (
      <Fragment>
        <PageHeader title={lang("reservation")} curPage="Réservation" />
        <div className="shop-cart padding-tb">
          <div className="container">
            <div className="text-center">
              <Spinner />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  // Error state
  if (fetchError) {
    return (
      <Fragment>
        <PageHeader title={lang("reservation")} curPage="Réservation" />
        <div className="shop-cart padding-tb">
          <div className="container">
            <div className="alert alert-danger">
              {lang("errorLoadingData")}: {fetchError.message}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <PageHeader title={lang("reservation")} curPage="Réservation" />
      <div className="shop-cart padding-tb" dir={dir}>
        <div className="container">
          {isLoading ? (
            <div className="text-center py-5">
              <Spinner />
              <p className="mt-3 text-muted">{lang("loading")}...</p>
            </div>
          ) : fetchError ? (
            <div className="alert alert-danger shadow-sm">
              <i className="bi bi-exclamation-triangle me-2"></i>
              {lang("errorLoadingData")}: {fetchError.message}
            </div>
          ) : (
            <div className="row g-4">
              <div className="col-lg-8">
                {!isAuthenticated && (
                  <div className="card shadow-sm border-0 rounded-3 mb-4">
                    <div className="card-body">
                      <LoginSignup />
                    </div>
                  </div>
                )}

                {/* Course Selection */}
                <div className="card shadow-sm border-0 rounded-3 mb-4">
                  <div className="card-body">
                    <h5 className="card-title mb-4">{lang("service")}</h5>
                    <select
                      className="form-select form-select-lg mb-3"
                      onChange={(e) => handleCoursChange(e.target.value)}
                    >
                      <option value="0">{lang("select")}</option>
                      {bookingData?.cours.map((cours, i) => (
                        <option key={i} value={i}>
                          {`${cours.matier} / ${cours.niveau} / ${cours.option}`}
                        </option>
                      ))}
                    </select>

                    {/* Programme Selection */}
                    {programme.length > 0 && (
                      <div className="mt-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h6 className="mb-0">{lang("Programme")}</h6>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={selectAll}
                              checked={isChecked}
                              id="selectAll"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="selectAll"
                            >
                              {lang("selectAll")}
                            </label>
                          </div>
                        </div>

                        <div className="row g-3">
                          {programme.map((prog, i) => (
                            <div className="col-md-6" key={prog.id}>
                              <div className="card h-100">
                                <div className="card-body">
                                  <div className="form-check">
                                    <input
                                      checked={prog.checked}
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`prog_${prog.id}`}
                                      onChange={() => handleCheckboxChange(i)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`prog_${prog.id}`}
                                    >
                                      {prog.nom}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Agenda Section */}
                <div className="card shadow-sm border-0 rounded-3">
                  <div className="card-body">
                    <h5 className="card-title mb-4">{lang("Schedule")}</h5>
                    <Agenda
                      dispo={bookingData?.dispo}
                      onclickTime={setTimeSelect}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                {/* Order Summary */}
                <div
                  className="z-0 card shadow-sm border-0 rounded-3 sticky-top"
                  style={{ top: "80px" }}
                >
                  <div className="card-header bg-primary text-white">
                    <h5 className="card-title mb-0">{lang("OrderSummary")}</h5>
                  </div>
                  <div className="card-body">
                    {/* Session Details */}
                    {timeSelect.fullDay && (
                      <div className="alert alert-info mb-3">
                        <small className="d-block mb-1">
                          {lang("SelectedDateTime")}:
                        </small>
                        <strong>{timeSelect.fullDay}</strong>
                        <br />
                        <strong>
                          {timeSelect.timeStart} - {timeSelect.timeEnd}
                        </strong>
                      </div>
                    )}

                    {/* Meeting Type Selection */}
                    <div className="mb-4">
                      <h6 className="mb-3">
                        {lang("ChoisissezTypeDeRendezVous")}:
                      </h6>
                      {bookingData?.rencontre?.map((ren) => (
                        <div
                          className="form-check custom-radio mb-2"
                          key={ren.value}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="typeRencontre"
                            id={`rencontre_${ren.value}`}
                            value={ren.value}
                            onChange={(e) =>
                              handleRencontreChange(e.target.value)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`rencontre_${ren.value}`}
                          >
                            {ren.label}
                          </label>
                        </div>
                      ))}
                    </div>

                    {/* Payment Details */}
                    <div className="border-top pt-3">
                      <div className="d-flex justify-content-between mb-2">
                        <span>{lang("Amount")}:</span>
                        <strong>
                          {price.price} {bookingData?.currency}
                        </strong>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <span>{lang("FixedFee")}:</span>
                        <strong>
                          {bookingData?.chargeFix} {bookingData?.currency}
                        </strong>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <span>
                          {lang("VariableFee")} ({bookingData?.chargePer}%):
                        </span>
                        <strong>
                          {price.PriceChargePer} {bookingData?.currency}
                        </strong>
                      </div>
                      <div className="d-flex justify-content-between mt-3 pt-3 border-top">
                        <h6 className="mb-0">{lang("Total")}:</h6>
                        <h6 className="mb-0">
                          {price.total} {bookingData?.currency}
                        </h6>
                      </div>
                    </div>

                    {/* Action Button and Payment Section */}
                    <div className="mt-4">
                      {/* Error Alert */}
                      {error && (
                        <div
                          className="alert alert-danger alert-dismissible fade show"
                          role="alert"
                        >
                          <i className="bi bi-exclamation-triangle-fill me-2"></i>
                          {error}
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>
                      )}

                      {/* Payment Button */}
                      {isAuthenticated ? (
                        <div>
                          <button
                            className="btn btn-primary btn-lg w-100 position-relative"
                            onClick={handlePayment}
                            disabled={paymentStatus.inProgress}
                          >
                            {paymentStatus.inProgress ? (
                              <span>
                                <span
                                  className="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                {lang("Processing")}...
                              </span>
                            ) : (
                              <>
                                <i className="bi bi-lock-fill me-2"></i>
                                {lang("confirmerReservation")}
                              </>
                            )}
                          </button>

                          {/* Stripe Redirect */}
                          {paymentStatus.inProgress && (
                            <div className="text-center mt-3">
                              {paymentStatus.publishableKey &&
                                paymentStatus.sessionId && (
                                  <div className="payment-processing-overlay">
                                    <StripeRedirect
                                      publishableKey={
                                        paymentStatus.publishableKey
                                      }
                                      sessionId={paymentStatus.sessionId}
                                    />
                                    <small className="d-block text-muted mt-2">
                                      <i className="bi bi-shield-lock me-1"></i>
                                      {lang("securePaymentProcessing")}
                                    </small>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="text-center">
                          <button
                            className="btn btn-secondary btn-lg w-100 mb-2"
                            disabled
                          >
                            <i className="bi bi-lock me-2"></i>
                            {lang("confirmerReservation")}
                          </button>
                          <small className="text-muted d-block">
                            <i className="bi bi-info-circle me-1"></i>
                            {lang("messageVeuillezConnecter")}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CartPage;
