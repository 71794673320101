import { Fragment, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import PageHeader from "../component/layout/pageheader";
import FilterGlobale from "../component/element/filterGlobale";
import FilterSecondary from "../component/element/filterSecondary";
import CardProvider from "../component/element/cardProvider";
import axios from "axios";
import { LanguageContext } from "../context/LanguageContext";
import Spinner from "../component/element/Spinner";

const CoursePage = () => {
  const location = useLocation();
  const { lang } = useContext(LanguageContext);

  const [selectedFilters, setSelectedFilters] = useState({
    cycle: location?.state?.selectedFilters?.cycle ?? {
      value: "all",
      label: lang("all"),
    },
    niveau: location?.state?.selectedFilters?.niveau ?? null,
    matiere: location?.state?.selectedFilters?.matiere ?? null,
    ville: location?.state?.selectedFilters?.ville ?? null,
    typeRencontre: location?.state?.selectedFilters?.typeRencontre ?? null,
  });

  const [selectedFiltersSecondary, setSelectedFiltersSecondary] = useState({
    genre: { value: 0, label: lang("all") },
    price: null,
    langueEn: null,
    typeRencontre: null,
  });

  const [annonceData, setAnnonceData] = useState({});
  const [providers, setProviders] = useState([]);
  const [page, setPage] = useState("?page=1");
  const [isLoading, setIsLoading] = useState({
    status: false,
    message: "",
  });

  const handlePageChange = (value) => {
    setPage(value);
    window.scrollTo({ top: 350, behavior: "smooth" });
  };

  const fetchData = async () => {
    setIsLoading({ status: true, message: lang("messageLoading") });

    const link = `/api/annonces${page.replace("/", "")}`;

    const typeRencontreIds =
      selectedFiltersSecondary.typeRencontre?.map((r) => r.value).join(",") ??
      null;

    const langueEnIds =
      selectedFiltersSecondary.langueEn?.map((l) => l.value).join(",") ?? null;

    try {
      await axios.get("/sanctum/csrf-cookie");
      const response = await axios.post(link, {
        cycle: selectedFilters.cycle.value,
        matiere: selectedFilters.matiere?.value,
        niveaux: selectedFilters.niveau?.value,
        ville: selectedFilters.ville?.label,
        genre: selectedFiltersSecondary.genre.value,
        price: selectedFiltersSecondary.price,
        typeRenc: typeRencontreIds,
        langueEn: langueEnIds,
      });

      setAnnonceData(response.data);

      if (page === "?page=1") {
        setProviders(response.data.data);
      } else {
        const arrayOfValues = Object.values(response.data.data ?? {});
        setProviders(arrayOfValues);
      }

      setIsLoading({ status: false, message: "" });
    } catch (error) {
      console.error("Erreur lors du fetch:", error);
      setIsLoading({ status: false, message: "" });
    }
  };

  useEffect(() => {
    if (
      selectedFilters.matiere &&
      selectedFilters.ville &&
      selectedFilters.niveau
    ) {
      fetchData();
    }
  }, [selectedFilters, selectedFiltersSecondary, page]);

  const handleSubmitFilters = (value) => {
    setSelectedFilters((prev) => ({ ...prev, ...value }));
  };

  const handleSubmitFiltersSecondary = (value) => {
    setSelectedFiltersSecondary((prev) => ({ ...prev, ...value }));
  };

  return (
    <Fragment>
      <PageHeader title={lang("experts_title")} curPage="Course Page" />

      {/* Filtres principaux */}
      <div className="group-select-section">
        <div className="container">
          <div className="section-wrapper">
            <div className="row align-items-center g-4">
              <div className="col-md-12">
                <div className="group-select-right">
                  <FilterGlobale
                    cycle={selectedFilters.cycle}
                    matiere={selectedFilters.matiere?.value}
                    ville={selectedFilters.ville}
                    niveau={selectedFilters.niveau?.value}
                    onClick={handleSubmitFilters}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Liste des cours / professeurs */}
      <div className="course-section py-5 section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="row">
              {/* Filtres secondaires */}
              <div className="col-md-3 text-center">
                <div className="card card-body">
                  <FilterSecondary
                    genre={selectedFiltersSecondary.genre}
                    langueEn={selectedFiltersSecondary.langueEn}
                    price={selectedFiltersSecondary.price}
                    typeRencontre={selectedFiltersSecondary.typeRencontre}
                    onClick={handleSubmitFiltersSecondary}
                  />
                </div>
              </div>

              {isLoading.status ? (
                <div className="col-md-9 text-center">
                  <Spinner />
                  <h5 className="mt-2">{isLoading.message}</h5>
                </div>
              ) : (
                <div className="col-md-9">
                  <div className="row justify-content-center row-cols-1">
                    {providers?.map((provider, i) => (
                      <div className="col pb-2" key={i}>
                        <div className="course-item">
                          <div className="course-inner">
                            <CardProvider
                              user={provider}
                              matieres={selectedFilters.matiere}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Pagination */}
                  <div className="mb-3">
                    {annonceData && (
                      <ul className="default-pagination lab-ul">
                        {/* Page précédente */}
                        {annonceData.current_page > 1 && (
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                handlePageChange(annonceData.prev_page_url)
                              }
                              className="btn"
                            >
                              <i className="icofont-rounded-left" />
                            </button>
                          </li>
                        )}

                        {/* Pages */}
                        {annonceData.links?.map((link, i) => {
                          // On ignore les liens sans URL ou ceux qui ont un label contenant "&"
                          if (!link.url || link.label.includes("&"))
                            return null;

                          return (
                            <li key={i}>
                              <button
                                type="button"
                                onClick={() => handlePageChange(link.url)}
                                className={
                                  annonceData.current_page ===
                                  Number(link.label)
                                    ? "btn btn-danger rounded-pill"
                                    : "btn btn-primary rounded-pill"
                                }
                                style={{ width: "40px", height: "40px" }}
                              >
                                {link.label}
                              </button>
                            </li>
                          );
                        })}

                        {/* Page suivante */}
                        {annonceData.current_page < annonceData.last_page && (
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                handlePageChange(annonceData.next_page_url)
                              }
                              className="btn"
                            >
                              <i className="icofont-rounded-right" />
                            </button>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CoursePage;
