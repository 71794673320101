import React, { createContext, useState, useEffect } from "react";
import Loading from "../component/element/loading";
import axios from "axios";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  // Récupération du langage sauvegardé ou fallback sur "fr".
  const savedLanguage = localStorage.getItem("appLanguage") || "fr";
  const [language, setLanguage] = useState(savedLanguage);
  const [dir, setDir] = useState(savedLanguage === "ar" ? "rtl" : "ltr");
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchJson = async (path) => {
    try {
      const response = await fetch(path);
      return response.ok ? response.json() : null;
    } catch {
      return null;
    }
  };

  const loadTranslations = async () => {
    setLoading(true);

    let data = await fetchJson(`/locales/${language}.json`);

    if (!data) {
      data = (await fetchJson(`/locales/fr.json`)) || {};
    }

    setTranslations(data);

    try {
      const response = await axios.post("/api/switch-lang", { lang: language });
      if (response.data.status !== 200) {
        console.error(
          "Erreur lors du switch de langue:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Erreur lors du switch de langue:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadTranslations();
    setDir(language === "ar" ? "rtl" : "ltr");
    localStorage.setItem("appLanguage", language);
    document.body.setAttribute("lang", language);
  }, [language]);

  const changeLanguage = (lang) => setLanguage(lang);

  const lang = (key) => translations[key] ?? key;

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, lang, dir }}>
      {loading ? <Loading /> : children}
    </LanguageContext.Provider>
  );
};
