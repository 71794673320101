import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Select from "react-select";
import { LanguageContext } from "../../context/LanguageContext";

const FilterGlobale = (props) => {
  const { lang, language } = useContext(LanguageContext);

  const [data, setData] = useState({
    cycle: [],
    niveau: [],
    matiere: [],
    ville: [],
  });
  const [error, setError] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    cycle: props.cycle ?? { value: "all", label: lang("all") },
    niveau: props.niveau ?? null,
    matiere: props.matiere ?? null,
    ville: props.ville ?? null,
  });

  const fetchData = async (url, transformer = (item) => item) => {
    try {
      const response = await axios.get(url);
      return response.data.data.map(transformer);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Error fetching data. Please try again.");
      return [];
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const [cycleData, villeData, niveauData] = await Promise.all([
          fetchData("/api/cycle", (item) => ({
            value: item.id,
            label: item.lang ? JSON.parse(item.lang)[language] : item.nom,
          })),
          fetchData("/api/ville/149", (item) => ({
            value: item.id,
            label: item.lang ? JSON.parse(item.lang)[language] : item.nom,
          })),
          fetchData("/api/niveaux", (item) => ({
            value: item.id,
            label: item.lang ? JSON.parse(item.lang)[language] : item.nom,
            ids: item.ids,
          })),
        ]);

        setData({
          cycle: [{ value: "all", label: lang("all") }, ...cycleData],
          ville: villeData,
          niveau: niveauData,
          matiere: [],
        });

        if (props.niveau) {
          const foundNiveau = niveauData.find(
            (niv) => niv.ids && niv.ids.includes(props.niveau)
          );
          if (foundNiveau) {
            handleNiveauChange(foundNiveau);
          }
        }

        setError(null);
      } catch {
        setError("Error loading initial data. Please try again.");
      }
    };

    loadInitialData();
  }, [lang, language]);

  const handleCycleChange = async (selectedCycle) => {
    setSelectedFilters((prev) => ({
      ...prev,
      cycle: selectedCycle,
      niveau: null,
      matiere: null,
    }));

    const endpoint =
      selectedCycle.value === "all"
        ? "/api/niveaux"
        : `/api/niveaux/${selectedCycle.value}`;

    try {
      const niveauData = await fetchData(endpoint, (item) => ({
        value: item.id,
        label: item.lang ? JSON.parse(item.lang)[language] : item.nom,
      }));

      setData((prevData) => ({
        ...prevData,
        niveau: niveauData,
        matiere: [],
      }));
      setError(null);
    } catch {
      setError("Error fetching niveau data. Please try again.");
    }
  };

  const handleNiveauChange = async (selectedNiveau) => {
    if (!selectedNiveau) return;

    const endpoint = `/api/matiere/${selectedNiveau.value}/${selectedFilters.cycle.value}`;
    try {
      const matiereData = await fetchData(endpoint, (item) => ({
        value: item.id,
        label: item.lang ? JSON.parse(item.lang)[language] : item.nom,
      }));

      let matchedMatiere = null;
      if (props.matiere) {
        matchedMatiere = matiereData.find((m) => m.value === props.matiere);
      }

      setData((prevData) => ({ ...prevData, matiere: matiereData }));
      setSelectedFilters((prev) => ({
        ...prev,
        niveau: selectedNiveau,
        matiere: matchedMatiere || null,
      }));
      setError(null);
    } catch {
      setError("Error fetching matiere data. Please try again.");
    }
  };

  const handleOtherChange = (field) => (selectedOption) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [field]: selectedOption,
    }));
  };

  const handleSubmit = () => {
    const { cycle, niveau, matiere, ville } = selectedFilters;
    if (cycle && niveau && matiere && ville) {
      props.onClick(selectedFilters);
      setError(null);
    } else {
      setError("Please select all options.");
    }
  };

  const styleSelect = {
    control: (base) => ({
      ...base,
      borderRadius: "10px",
      border: "1px solid #dee2e6",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #198754",
      },
    }),
  };

  return (
    <div className={`card bg-light ${props.class}`} style={props.style}>
      <div className="card-body p-4">
        {error && (
          <div
            className="alert alert-danger alert-dismissible fade show mb-4"
            role="alert"
          >
            <i className="bi bi-exclamation-triangle-fill me-2"></i>
            {error}
            <button
              type="button"
              className="btn-close"
              onClick={() => setError(null)}
            ></button>
          </div>
        )}

        <div className="row g-4">
          <div className="col-md-3 col-sm-6">
            <div className="form-group position-relative">
              <label className="fw-medium mb-2 text-dark d-flex align-items-center">
                <i className="icofont-users-alt-2 text-success me-2"></i>
                {lang("domain")}
              </label>
              <Select
                className="shadow-sm"
                placeholder={lang("choose")}
                options={data.cycle}
                value={selectedFilters.cycle}
                onChange={handleCycleChange}
                isLoading={!data.cycle.length}
                styles={styleSelect}
              />
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group position-relative">
              <label className="fw-medium mb-2 text-dark d-flex align-items-center">
                <i className="icofont-graduate text-success me-2"></i>
                {lang("speciality")}
              </label>
              <Select
                className="shadow-sm"
                placeholder={lang("choose")}
                options={data.niveau}
                value={selectedFilters.niveau}
                onChange={handleNiveauChange}
                isLoading={!data.niveau.length}
                styles={styleSelect}
              />
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group position-relative">
              <label className="fw-medium mb-2 text-dark d-flex align-items-center">
                <i className="icofont-tasks text-success me-2"></i>
                {lang("service")}
              </label>
              <Select
                className="shadow-sm"
                placeholder={lang("choose")}
                options={data.matiere}
                value={selectedFilters.matiere}
                onChange={handleOtherChange("matiere")}
                isLoading={!data.matiere.length}
                styles={styleSelect}
              />
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group position-relative">
              <label className="fw-medium mb-2 text-dark d-flex align-items-center">
                <i className="icofont-location-pin text-success me-2"></i>
                {lang("city")}
              </label>
              <Select
                className="shadow-sm"
                placeholder={lang("choose")}
                options={data.ville}
                value={selectedFilters.ville}
                onChange={handleOtherChange("ville")}
                isLoading={!data.ville.length}
                styles={styleSelect}
              />
            </div>
          </div>
        </div>

        <div className="text-center mt-4 pt-2">
          <button
            type="button"
            className="btn btn-success btn-lg px-2 py-1 rounded-pill shadow-sm hover-scale"
            onClick={handleSubmit}
            style={{
              transition: "transform 0.5s",
            }}
          >
            <i className="bi bi-search me-2"></i>
            {lang("search")}
            <span className="ms-2 badge bg-light text-success rounded-pill">
              {Object.values(selectedFilters).filter(Boolean).length}/4
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterGlobale;
